import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { gql } from '@faststore/graphql-utils'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo } from 'react'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import RenderCMS from 'src/components/RenderCMS'
import { useInfoBar } from 'src/hooks/infoBar'
import type { InfoBarData } from 'src/hooks/infoBar/types'
import type { Section } from 'src/cms/client'
import { getAllCMSPageDataByContentType } from 'src/cms/client'

import config from '../../store.config'

type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery & {
    cms?: { sections: Section[]; infoBar: InfoBarData }
    error?: any
  }
> & { slug: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    serverData,
  } = props

  const selectedFacets = serverData?.collection?.meta.selectedFacets

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    const sort =
      href && new URL(href)?.searchParams.get('sort')
        ? maybeState?.sort
        : 'orders_desc'

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: sort ?? 'orders_desc',
    }
  }, [href, pathname, selectedFacets])
}

function Page(props: Props) {
  const {
    data: { site },
    serverData: { collection, cms, error },
    location: { pathname, search },
  } = props

  const searchParams = useSearchParams(props)

  const collectionTitle = [
    ...(collection?.breadcrumbList.itemListElement ?? []),
  ].pop()?.name

  const title = collection?.seo.title ?? site?.siteMetadata?.title ?? ''

  const pathnames = pathname
    .split('/')
    .filter((path) => String(path).trim())
    .join('/')

  const canonical = `${config.storeUrl}/${pathnames}${
    search.match(/page=\d/g)?.length
      ? `${
          search.match(/page=\d/g)?.join('') === 'page=0'
            ? ''
            : `?${search.match(/page=\d/g)?.join('')}`
        }`
      : ''
  }`

  const page =
    search.match(/page=\d/g) &&
    search
      .match(/page=\d/g)
      ?.join('')
      .split('=')[1]

  const notFound = !collection || !cms

  error && console.log(error)

  useEffect(() => {
    if (notFound) {
      window.location.href = `/404/?from=${encodeURIComponent(
        window.location.pathname
      )}`
    }
  }, [notFound])

  const { setData } = useInfoBar()

  useEffect(() => {
    !!cms?.infoBar?.length && setData(cms.infoBar)
  }, [cms?.infoBar, setData])

  // Collection not found
  if (notFound) {
    return null
  }

  const { sections } = cms

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      {/* Adding link next and prev from server side for better SEO tracking */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={
          collection?.seo?.description ?? site?.siteMetadata?.description
        }
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={
          page
            ? [
                {
                  rel: 'prev',
                  href: `${
                    parseInt(page, 10) - 1 === 0
                      ? `${config.storeUrl}/${pathnames}${search.replace(
                          /\?*&*page=\d*/g,
                          ''
                        )}`
                      : `${config.storeUrl}/${pathnames}${search.replace(
                          /page=\d*/g,
                          `page=${parseInt(page, 10) - 1}`
                        )}`
                  }`,
                },
                {
                  rel: 'next',
                  href: `${config.storeUrl}/${pathnames}${search.replace(
                    /page=\d*/g,
                    `page=${parseInt(page, 10) + 1}`
                  )}`,
                },
              ]
            : [
                {
                  rel: 'next',
                  href: `${config.storeUrl}/${pathnames}${search}?page=1`,
                },
              ]
        }
      />
      <BreadcrumbJsonLd
        itemListElements={collection?.breadcrumbList.itemListElement ?? []}
      />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <RenderCMS
        sections={sections}
        extraProps={{
          ProductGallery: {
            title: collectionTitle,
            shouldShowTitle: !sections.some(
              (section) => section.name === 'MainBannerDepartment'
            ),
          },
          Breadcrumb: {
            breadcrumbList: collection?.breadcrumbList.itemListElement,
            name: title,
          },
          CategoryCarousel: {
            showPagination: false,
          },
        }}
      />
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query CollectionPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

/**
 * This query is run during SSR
 * */
export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      treePath

      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  try {
    const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`
    const { isNotFoundError } = await import('@faststore/api')
    const { execute } = await import('src/server')
    const [{ data, errors = [] }, cmsData] = await Promise.all([
      execute<any, ServerCollectionPageQueryQuery>({
        operationName: querySSR,
        variables: { slug },
      }),
      getAllCMSPageDataByContentType('plp'),
    ])

    const notFound = errors.find(isNotFoundError)

    if (notFound) {
      const params = new URLSearchParams({
        q: encodeURIComponent(
          `/${slug
            // eslint-disable-next-line prefer-regex-literals
            .replace(new RegExp('/', 'g'), ' ')
            // eslint-disable-next-line prefer-regex-literals
            .replace(new RegExp('-', 'g'), ' ')}`
        ),
      })

      return {
        status: 308,
        props: {},
        headers: {
          'cache-control': 'public, max-age=31536000',
          location: `/s/?${params.toString()}`,
        },
      }
    }

    const { collection } = data as ServerCollectionPageQueryQuery

    const defaultPage = cmsData.find((page) => page.config?.config?.id === '0')

    const getSections = () => {
      for (let i = collection?.treePath?.length ?? 0; i > 0; i--) {
        const existing = cmsData.find(
          (page) => page.config?.config?.id === collection?.treePath?.[i - 1]
        )

        if (existing) {
          if (i === collection?.treePath?.length) {
            return existing.sections
          }

          const parentSeoText = existing.sections.find(
            (parentSection) => parentSection.name === 'PLPSeoText'
          )

          const parentBanners = existing.sections.find(
            (parentSection) =>
              parentSection.name === 'Carousel' &&
              parentSection.data.template === 'PLP'
          )

          const aux = [...(defaultPage?.sections ?? [])]

          if (parentBanners) {
            if (
              defaultPage?.sections.some(
                (section) =>
                  section.name === 'Carousel' && section.data.template === 'PLP'
              )
            ) {
              return defaultPage?.sections.reduce((acc, section, index) => {
                if (
                  section.name === 'Carousel' &&
                  section.data.template === 'PLP'
                ) {
                  acc[index] = parentBanners
                }

                return acc
              }, [] as any[])
            }

            aux.splice(aux.length - 1, 0, parentBanners)
          }

          if (parentSeoText) {
            if (
              defaultPage?.sections.some(
                (section) => section.name === 'PLPSeoText'
              )
            ) {
              return defaultPage?.sections.reduce((acc, section, index) => {
                if (section.name === 'PLPSeoText') {
                  acc[index] = parentSeoText
                }

                return acc
              }, [] as any[])
            }

            aux.splice(aux.length - 1, 0, parentSeoText)
          }

          return aux
        }
      }

      return defaultPage?.sections ?? []
    }

    // console.log(cmsData[0].infoBar)

    const getInfobar = () => {
      for (let i = collection?.treePath?.length ?? 0; i > 0; i--) {
        const existing = cmsData.find(
          (page) => page.config?.config?.id === collection?.treePath?.[i - 1]
        )

        if (existing?.infoBar?.infoBar?.links) {
          return existing.infoBar?.infoBar?.links
        }
      }

      return []
    }

    return {
      status: 200,
      props:
        { ...data, cms: { sections: getSections(), infoBar: getInfobar() } } ??
        {},
      headers: {
        'cache-control': ONE_YEAR_CACHE,
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: { error: err },
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
